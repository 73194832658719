.contactWrapper {
    max-width: 700px;
    margin: auto;
    border-radius: 1.2rem;
  }
  
  .contactCopy {
    text-align: center;
    font-weight: 200;
    margin: 2.4rem 0;
    margin-top: 15vh;
    font-size: 18px;
  }
  
    .contactCopy a {
    color: var(--brand);
    font-weight: 500;
    }

    .contactCopy a:hover {
    text-decoration: underline;
    }
  
  .contactEmail {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    width: fit-content;
    font-size: 24px;
    margin: auto;
    transition: 0.5s;
    padding: 12px 32px 12px 32px;
    border-radius: 1rem;
    background: transparent;
    margin-top: -4vh;
  }
  
  .contactEmail:hover {
    color: var(--brand);
  }
  