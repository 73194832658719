
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #FFFEFC;
  } */
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .circle {
    position: relative;
    background-color: transparent;
    width: 70px;
    height: 100px; 
    animation: animate 9s linear infinite;
    transform-style: preserve-3d;
    --deg: calc(360deg / var(--numchs));
    font-family: monospace;
  }
  
  @keyframes animate {
    0% {
      transform: rotateY(360deg) rotateX(3deg);
    }

    100% {
      transform: rotateY(0deg) rotateX(3deg);
    }
  }
  
  .char {
    position: absolute;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    color: black;
    border: 2.5px solid;
    border-left: 0;
    border-right: 0;
    font-size: 4.5em;
    letter-spacing: 0.2em;
    top: 50%;
    left: 50%;
    transform-style: preserve-3d;
    transform-origin: center;
    --spacing: 180px;
    transform: rotateY(calc(var(--char-index) * var(--deg) * 1)) translateZ(calc(var(--spacing) * 1.85));
  }

