.stats {
    position: relative;
  }
  
  .statColumn h4 {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
  }
  
  .statColumn h4 span {
    font-size: var(--text-md);
    font-weight: bold;
    position: relative;
    margin-left: 0.8rem;
  }
  
  .statGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    margin-bottom: 4.8rem;
  }
  
  .chip {
    font-size: 14px;
    background: var(--background-light);
    padding: 0.2rem 0.8rem;
    border-radius: 999px;
  }
