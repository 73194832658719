.sectionHeader {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
}

.title {
  font-size: 5.6rem;
  font-weight: 900;
  text-align: end;
}

.title span {
  color: #909F90;
}

@media (max-width: 768px) {
  .title {
    font-size: 3.6rem;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: gray;
  opacity: 0.3;
}
